import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Navigation from "../components/navigation";
import Hero from "../components/hero";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BookRecommendation from "../components/book-recommendation";
import ReactGA from "react-ga";
import Book1 from '../images/books/head-first-python.jpg'
import Book2 from '../images/books/algorithms-unlocked.jpg'
import Book3 from '../images/books/introduction-to-algorithms.jpg'
import DonationFooter from "../components/donation-footer";

class Books extends React.Component {

	componentDidMount() {
		ReactGA.initialize("UA-115464417-1");
		if (!(window.location.hostname === "localhost")) {
			ReactGA.pageview(window.location.pathname + window.location.search);
		}
	}

	render() {
		return (
			<Layout>
				<SEO title="Curated List of Books for Programmers" keywords={[`curated list of books for programmers, programming books, books for programmers, list of books for programmers`]} />
				
				<Navigation />
				
				<Hero
					header="Books"
					subHeader="I have listed some of the books that have helped me in my journey of becoming a competent programmer. Have a look. Something might catch your eye."
					maxWidth={650}
				/>
				
				<Container>
					<Row>
						<BookRecommendation
							imageSrc={Book1}
							linkUS="https://amzn.to/2N8DXWd"
							linkIndia="https://amzn.to/2RFKyb1"
						/>
						<BookRecommendation
							imageSrc={Book2}
							linkUS="https://amzn.to/2Eftkhp"
							linkIndia="https://amzn.to/2DNpwTa"
						/>
						<BookRecommendation
							imageSrc={Book3}
							linkUS="https://amzn.to/2Nb5juK"
							linkIndia="https://amzn.to/2DR9mrI"
						/>
					</Row>
				</Container>
				<DonationFooter ad={false}></DonationFooter>
			</Layout>
		);
	}
}

export default Books;
