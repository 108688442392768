import React from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const BookRecommendation = props => {
	return (
		<Col xs={6} md={3} lg={2} style={{ padding: "1rem" }}>
			<img className="card-1" src={props.imageSrc} alt={props.name} />
			<Row style={{display: 'flex', justifyContent: 'center', marginBottom: 10}}>
				<a href={props.linkUS} target="_blank" rel="noopener noreferrer" style={{width: '80%'}}>
					<Button size="sm" variant="dark" block>
						amazon.com
					</Button>
				</a>
			</Row>
			<Row style={{display: 'flex', justifyContent: 'center', marginBottom: 10}}>
				<a href={props.linkIndia} target="_blank" rel="noopener noreferrer" style={{width: '80%'}}>
					<Button size="sm" variant="dark" block>
						amazon.in
					</Button>
				</a>
			</Row>
		</Col>
	);
};

BookRecommendation.propTypes = {};

export default BookRecommendation;
